<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <p class="mb-2">
        <span>Rapport sur les échouées.</span>
      </p>
    </b-col>
    <b-col cols="12">
      <apex-line-area-chart />
    </b-col>
    <!-- <b-col cols="12">
      <apex-data-bar-chart />
    </b-col>
    <b-col cols="12">
      <apex-scatter-chart />
    </b-col>
    <b-col cols="12">
      <apex-line-chart />
    </b-col>
    <b-col md="6">
      <apex-bar-chart />
    </b-col>
    <b-col md="6">
      <apex-candlestick-chart />
    </b-col>
    <b-col md="6">
      <apex-heat-map-chart />
    </b-col>
    <b-col md="6">
      <apex-radial-bar-chart />
    </b-col>
    <b-col md="6">
      <apex-radar-chart />
    </b-col>
    <b-col md="6">
      <apex-donut-chart />
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import ApexLineChart from '@core/components/charts/apex-chart/ApexLineChart.vue'
import ApexLineAreaChart from '@core/components/charts/apex-chart/ApexLineAreaChart.vue'
// import ApexDataBarChart from '@core/components/charts/apex-chart/ApexDataBarChart.vue'
// import ApexBarChart from '@core/components/charts/apex-chart/ApexBarChart.vue'
// import ApexCandlestickChart from '@core/components/charts/apex-chart/ApexCandlestickChart.vue'
// import ApexScatterChart from '@core/components/charts/apex-chart/ApexScatterChart.vue'
// import ApexHeatMapChart from '@core/components/charts/apex-chart/ApexHeatMapChart.vue'
// import ApexDonutChart from '@core/components/charts/apex-chart/ApexDonutChart.vue'
// import ApexRadialBarChart from '@core/components/charts/apex-chart/ApexRadialBarChart.vue'
// import ApexRadarChart from '@core/components/charts/apex-chart/ApexRadarChart.vue'

export default {
  components: {
    BRow,
    BCol,

    // ApexLineChart,
    ApexLineAreaChart,
    // ApexDataBarChart,
    // ApexBarChart,
    // ApexCandlestickChart,
    // ApexScatterChart,
    // ApexHeatMapChart,
    // ApexDonutChart,
    // ApexRadarChart,
    // ApexRadialBarChart,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
