<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Commande échouées (en €)
          </b-card-title>
          <b-card-sub-title>En France</b-card-sub-title>
        </div>
        <!--/ title and subtitle -->

        <!-- datepicker -->
        <!-- <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range'}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div> -->
        <!-- datepicker -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="area"
          height="400"
          :options="{...apexChatData.lineAreaChartSpline.chartOptions, xaxis: {categories: betweenMonths}}"
          :series="series"
        />
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Commande échouées ( en nombre )
          </b-card-title>
          <b-card-sub-title>En France</b-card-sub-title>
        </div>
        <!--/ title and subtitle -->

        <!-- datepicker -->
        <!-- <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range'}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div> -->
        <!-- datepicker -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="area"
          height="400"
          :options="{...apexChatData.lineAreaChartSpline.chartOptions, xaxis: {categories: betweenMonths}}"
          :series="seriesNumber"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      series: [],
      seriesNumber: [],
      isAlready: false,
      betweenMonths: [],
    }
  },
  async mounted() {
    const startDate = this.$moment('2021-03-01')
    const endDate = this.$moment()

    if (startDate < endDate) {
      const date = startDate.startOf('month')

      while (date < endDate.endOf('month')) {
        this.betweenMonths.push(date.format('YY/MM'))
        date.add(1, 'month')
      }
    }
    const {
      chiffres,
      chiffresEuros,
      numbers,
      numberTotal,
    } = await this.$http.get('/admin/orders/failed-report')
    this.series = [
      {
        name: 'Commandes échouées ( en € )',
        data: chiffres,
      },
      {
        name: 'Commandes récupérées ( en € )',
        data: chiffresEuros,
      },
    ]
    this.seriesNumber = [
      {
        name: 'Commandes totales',
        data: numbers,
      },
      {
        name: 'Commandes récupérées',
        data: numberTotal,
      },
    ]
    this.isAlready = true
  },
}
</script>
